import "./App.css";
import QRCode from "qrcode.react";
import Container from "@mui/material/Container";
import { Grid, TextField, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

function App() {
  const [unitType, setUnitType] = useState("nController Max");
  const [qrContent, setQRContent] = useState("Hello");

  const handleUnitChange = (event) => {
    setUnitType(event.target.value);
  };

  const getRandomMac = () => {
    return "XX:XX:XX:XX:XX:XX".replace(/X/g, function () {
      return "0123456789ABCDEF".charAt(Math.floor(Math.random() * 16));
    }).toLowerCase();
  };

  const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random() 
            * (max - min + 1)) + min;
  };

  const getRandoSerial = () => {
    return (
      "XXX".replace(/X/g, function () {
        return "ABCDEFGHIJKLMNOPQRSTUVWXYZ".charAt(
          Math.floor(Math.random() * 3)
        );
      }) +
      "XXXXX".replace(/X/g, function () {
        return "0123456789".charAt(Math.floor(Math.random() * 5));
      })
    );
  };

  useEffect(() => {
    let content = {
      mac_address: getRandomMac(),
      serial: getRandoSerial(),
      type: unitType,
    };

    if (unitType === "nController Max" || unitType === "nDriver Max") {
      content.led_ports = randomNumberInRange(1,6);
    }

    if (unitType === "nPanel+" || unitType === "nPanel") {
      content.watt = 40;
      content.voltage = 40;
    }

    if (unitType === "lNode") {
      content.watt = randomNumberInRange(3,35);
    }

    setQRContent(JSON.stringify(content, null, 2));
  }, [unitType]);
  return (
    <Container fixed>
      <Box pt={3}>
        <Grid container spacing={2}>
          {/* Header Section */}
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              nLuminarie Random QR Code Generator
            </Typography>
          <br />
          <br />
          </Grid>
    
          {/* Content Section */}
          <Grid item container xs={12} spacing={2}>
            {/* Left Side - RadioGroup */}
            <Grid item xs={12} sm={6}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Unit Type</FormLabel>
                <RadioGroup
                  aria-label="unit"
                  name="row-radio-buttons-group"
                  value={unitType}
                  onChange={handleUnitChange}
                >
                  <FormControlLabel
                    value="nController Max"
                    control={<Radio />}
                    label="nController Max"
                  />
                  <FormControlLabel
                    value="nDriver Max"
                    control={<Radio />}
                    label="nDriver Max"
                  />
                  <FormControlLabel
                    value="eDriver"
                    control={<Radio />}
                    label="eDriver"
                  />
                  <FormControlLabel
                    value="nController"
                    control={<Radio />}
                    label="nController"
                  />
                  <FormControlLabel
                    value="nDriver"
                    control={<Radio />}
                    label="nDriver"
                  /> 
                  <FormControlLabel
                    value="nSensor"
                    control={<Radio />}
                    label="nSensor"
                  />
                  <FormControlLabel
                    value="nSwitch"
                    control={<Radio />}
                    label="nSwitch"
                  />
                  {/* <FormControlLabel
                    value="nPanel"
                    control={<Radio />}
                    label="nPanel"
                  />
                  <FormControlLabel
                    value="nPanel+"
                    control={<Radio />}
                    label="nPanel+"
                  /> */}
                  <FormControlLabel
                    value="mstrNode"
                    control={<Radio />}
                    label="Master Node"
                  />
                  <FormControlLabel
                    value="mstrNodePlus"
                    control={<Radio />}
                    label="Master Node Plus"
                  />
                  <FormControlLabel
                    value="lNode"
                    control={<Radio />}
                    label="lNode"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {/* Right Side - QRCode and TextField */}
            <Grid item xs={12} sm={6} container direction="column" alignItems="center">
              <Grid item>
                <QRCode value={qrContent} size={256} />
              </Grid>
              <br />
              <br />
              <Grid item>
                <TextField
                  sx={{ m: 1, width: "40ch" }}
                  maxRows={7}
                  multiline
                  disabled
                  value={qrContent}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default App;
